import { type ReactNode } from 'react';

import { Theme } from '../types';

import styles from './ThemeOverride.module.css';

export function ThemeOverride({ theme, children }: { theme: Theme; children: ReactNode }) {
  return (
    <div className={styles.override} data-theme={theme}>
      {children}
    </div>
  );
}
